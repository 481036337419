import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { Display, HeadingSmall, Lead } from "../components/typography"
import PageTitle from "../components/pageTitle"
import { LinkButton } from "../components/button"

const SuccessfulRegistrationPage = () => {
  return (
    <Layout>
      <SEO title="Contatti" />
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <PageTitle>
                <Display align="center">Richiesta di registrazione confermata</Display>
              </PageTitle>
            </Col>
          </Row>
          <Row justify="center">
            <Col md={8}>
              <HeadingSmall align="center">
                Registrazione avvenuta con succeso, il tuo account è in attesa
                di verifica, riceverai un'e-mail non appena sarà abilitato.
              </HeadingSmall>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "3rem",
                }}
              >
                <LinkButton to="/">Torna all'home page</LinkButton>
              </div>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default SuccessfulRegistrationPage
